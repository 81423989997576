import React, { useState } from 'react';
import css from './AvailabilityTable.module.css';
import classNames from 'classnames';
import { ONBOARDING_TYPE_SERVICE } from '../../config/configCustomListing';

const AvailabilityTable = ({ data, className, onboardingType = ONBOARDING_TYPE_SERVICE }) => {
  const [selectedCell, setSelectedCell] = useState(null);
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const classNameDefault = classNames(className, css.table);

  const handleCellClick = (period, day) => {
    const cellId = `${period.period}-${day}`;
    if (period.days[day] && period.days[day].length > 1) {
      setSelectedCell(selectedCell === cellId ? null : cellId); // Toggle on click only if more than one slot
    }
  };

  const isTooltipVisible = (period, day) => {
    const cellId = `${period.period}-${day}`;
    return selectedCell === cellId && period.days[day] && period.days[day].length > 1;
  };

  return (
    <table className={classNameDefault}>
      <thead>
        <tr>
          <th className={classNames(css.header, css.firstColumn, css.whiteCell)}>
            {onboardingType === ONBOARDING_TYPE_SERVICE ? 'Availability' : 'Schedule'}
          </th>
          {daysOfWeek.map(day => (
            <th key={day} className={`${css.header} ${css.otherColumns}`}>
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map(period => (
          <tr key={period.period}>
            <td className={`${css.cell} ${css.firstColumn}`}>
              {period.period.charAt(0).toUpperCase() + period.period.slice(1)}
            </td>
            {daysOfWeek.map(day => {
              const slots = period.days[day];
              const cellId = `${period.period}-${day}`;
              return (
                <td
                  key={day}
                  className={classNames(
                    css.cell,
                    css.withBorder,
                    slots ? css.cellAvailable : css.cellUnavailable
                  )}
                  onClick={() => handleCellClick(period, day)}
                  onMouseEnter={() => setSelectedCell(cellId)}
                  onMouseLeave={() => setSelectedCell(null)}
                >
                  {slots && slots.length ? (
                    <div className={css.innerCell}>
                      <>
                        <span>{`${slots[0].startTime} - ${slots[0].endTime}`}</span>
                        {slots.length > 1 && <span className={css.moreText}>more</span>}
                      </>
                    </div>
                  ) : (
                    ''
                  )}
                  {slots && slots.length > 1 && (
                    <div
                      className={`${css.tooltip} ${
                        isTooltipVisible(period, day) ? css.tooltipVisible : ''
                      }`}
                    >
                      {slots.map((slot, index) => (
                        <div key={index}>{`${slot.startTime} - ${slot.endTime}`}</div>
                      ))}
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AvailabilityTable;
