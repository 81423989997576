import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import { ensureOwnListing } from '../../../../util/data';
import { ListingLink } from '../../../../components';
import EditListingCustomTextForm from './EditListingCustomTextForm';

import css from './EditListingCustomTextPanel.module.css';

const getMessageId = (baseId, tab, listingType, intl) => {
  const categoryId = `${baseId}_${tab}_${listingType}`;
  return intl.messages[categoryId] ? categoryId : `${baseId}_${tab}`;
};
const getFormMessagesID = (baseId, tab, sufix, listingType, intl) => {
  const categoryId = `${baseId}.${tab}${sufix}_${listingType}`;
  return intl.messages[categoryId] ? categoryId : `${baseId}.${tab}${sufix}`;
};

const formConfig = (tab, listingType, intl) => {
  return {
    TAB: tab,
    labelMessageId: getFormMessagesID('EditListingCustomTextForm', tab, 'Label', listingType, intl),
    placeholderMessageId: getFormMessagesID(
      'EditListingCustomTextForm',
      tab,
      'Placeholder',
      listingType,
      intl
    ),
  };
};

const EditListingCustomTextPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    tab,
    intl,
    config,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { listingType } = publicData;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const tabTitleId = getMessageId(
    'EditListingCustomTextPanel.createListingTitle',
    tab,
    listingType,
    intl
  );
  const tabLabelId = getMessageId('EditListingWizard.tabTitle', tab, listingType, intl);

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCustomTextPanel.title"
      values={{
        tabTitle: <FormattedMessage id={tabLabelId} />,
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingCustomTextPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id={tabTitleId} />
  );

  return (
    <div className={classes}>
      <div className={props.containerTopPanelClassName}>
        <h3 className={css.title}>{panelTitle}</h3> {props.progressBarComponent}
      </div>
      <EditListingCustomTextForm
        className={css.form}
        publicData={publicData}
        formConfig={formConfig(tab, listingType, intl)}
        initialValues={{ [tab]: publicData[tab] }}
        onSubmit={values => {
          const { [tab]: tabValue = '' } = values;
          const updateValues = {
            publicData: {
              [tab]: tabValue,
            },
          };
          onSubmit(updateValues);
        }}
        onTextChange={values => {
          const { [tab]: tabValue = '' } = values;
          const updateValues = {
            publicData: {
              [tab]: tabValue,
            },
          };
          props.onSaveTextbox(updateValues);
        }}
        disabled={disabled}
        ready={ready}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCustomTextPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCustomTextPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCustomTextPanel;
