import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import css from './ImagePicker.module.css';

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const metadata = {
          type: 'image/jpeg',
        };
        const file = new File([blob], 'croppedImage.jpg', metadata);
        resolve(file);
      },
      'image/jpeg',
      1
    );
  });
}

const CropperTool = props => {
  const { image, circular = false, onChangeImage, onCancelClick } = props;
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    if (croppedImage) {
      onChangeImage(croppedImage);
      setZoom(1);
    }
  }, [croppedImage]);

  const [zoom, setZoom] = useState(1);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onSaveCrop = async () => {
    try {
      const cropped = await getCroppedImg(image, croppedAreaPixels, 0);
      setCroppedImage(cropped);
    } catch (e) {
      console.error(e);
    }
  };

  if (!image) return <></>;

  return (
    <>
      <div className={css.cropperContainer}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          cropShape={circular ? 'round' : 'rect'}
          showGrid={true}
          onCropChange={onCropChange}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>
      <div className={css.controlContainer}>
        <div className={css.zoomContainer}>
          <p className={css.zoomOut}>-</p>
          <div className={css.rangeSelector}>
            <input
              disabled={!image}
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={e => {
                setZoom(e.target.value);
              }}
              className={css.zoomRange}
            />
          </div>
          <p className={css.zoomIn}>+</p>
        </div>

        <div className={css.buttonsContainer}>
          <button disabled={!image} className={css.saveButton} type="button" onClick={onSaveCrop}>
            Save
          </button>
          {
            <button
              disabled={!image}
              className={css.cancelButton}
              type="button"
              onClick={e => onCancelClick(e)}
            >
              Cancel
            </button>
          }
        </div>
      </div>
    </>
  );
};

CropperTool.defaultProps = {};

CropperTool.propTypes = {};

export default CropperTool;
