// This function checks the progress of the user in completing certain tabs
// Parameters:
// - data: an object containing the publicData
// - isTabCompleted: an object containing information about which tabs are completed

import { getFieldsForTab } from '../config/configCustomListing';

const FIRST_COMMOM_TABS = ['details', 'extendedDetails', 'jobFilters', 'location'];
const LAST_COMMOM_TABS = ['pricing', 'availability', 'photos'];

export const JOB_TYPE_SERVICE_TABS = {
  part_time: [...FIRST_COMMOM_TABS, 'dearFamily', 'favMemory', 'experience', ...LAST_COMMOM_TABS],
};

export const JOB_TYPE_JOB_TABS = {
  part_time: [
    ...FIRST_COMMOM_TABS,
    'aboutFamily',
    'jobDescription',
    'familyExpectations',
    'photos',
  ],
};

export const TABS_CHILDCARE = [
  ...FIRST_COMMOM_TABS,
  'dearFamily',
  'favMemory',
  'experience',
  'personality',
  'myFamily',
  'eduLanguages',
  'interests',
  'motivation',
  'expectation',
  ...LAST_COMMOM_TABS,
];

export const TABS_TUTOR = [
  ...FIRST_COMMOM_TABS,
  'dearFamily',
  'favMemory',
  'experience',
  'skillsTalents',
  'personality',
  'eduLanguages',
  'interests',
  'motivation',
  'expectation',
  ...LAST_COMMOM_TABS,
];

export const TABS_ACTIVITY_BASED = [
  ...FIRST_COMMOM_TABS,
  'dearFamily',
  'experience',
  'skillsTalents',
  'favMemory',
  'personality',
  'eduLanguages',
  'interests',
  'motivation',
  'expectation',
  ...LAST_COMMOM_TABS,
];

export const TABS_PREGNANCY_AND_BIRTH = [
  ...FIRST_COMMOM_TABS,
  'dearFamily',
  'favMemory',
  'experience',
  'eduLanguages',
  'expectation',
  ...LAST_COMMOM_TABS,
];

export const TABS_CRECHE = [
  ...FIRST_COMMOM_TABS,
  'dearFamily',
  'approachPhilosophy',
  'facilitiesAmenities',
  'staffQualifications',
  'safetySecurityMeasures',
  'nutritionMealPlans',
  'parentCommunicationUpdates',
  'inclusionDiversity',
  'photos',
];

export const TABS_CHILDCARE_PARENT = [
  ...FIRST_COMMOM_TABS,
  'aboutFamily',
  'jobDescription',
  'familyRoutine',
  'homeEnvironment',
  'parentingStyle',
  'familyExpectations',
  'healthNotes',
  'compensationBenefits',
  'qualificationsExperience',
  'additionalInfo',
  'photos',
];

export const TABS_PREGNANCY_AND_BIRTH_PARENT = [
  ...FIRST_COMMOM_TABS,
  'jobDescription',
  'qualificationsExperience',
  'additionalInfo',
  'photos',
];

export const TABS_CRECHE_PARENT = [
  ...FIRST_COMMOM_TABS,
  'jobDescription',
  'qualificationsExperience',
  'additionalInfo',
  'photos',
];

export const TABS_ACTIVITY_BASED_PARENT = [
  ...FIRST_COMMOM_TABS,
  'jobDescription',
  'healthNotes',
  'qualificationsExperience',
  'additionalInfo',
  'photos',
];

export const TABS_TUTOR_PARENT = [
  ...FIRST_COMMOM_TABS,
  'jobDescription',
  'qualificationsExperience',
  'additionalInfo',
  'photos',
];
const DEFAULT_JOB_TYPE = 'default';

const checkTabCompletion = (formValues = {}, publicData, category, onboardingType, jobType) => {
  const detailsFields = getFieldsForTab('details', category, onboardingType);
  let extendedDetailsFields = getFieldsForTab('extendedDetails', category, onboardingType);

  const checkFieldsFilled = fields => fields.every(field => formValues[field] || publicData[field]);

  // Check and add 'jobType' to 'detailsFields' only if it's not already included
  const jobTypeField = null; //DETAILS_JOBTYPE[category];
  if (jobTypeField && !detailsFields.includes(jobTypeField)) {
    detailsFields.push(jobTypeField);
  }
  // Filter out certain fields from 'extendedDetailsFields' based on 'jobType'
  if (jobType !== 'full-time') {
    extendedDetailsFields = extendedDetailsFields.filter(
      field => field !== 'durationMin' && field !== 'durationMax'
    );
  }

  const isDetailsCompleted = checkFieldsFilled(detailsFields);

  // Check 'Extended Details' Tab
  //const extendedDetailsFields = extendedDetailsFieldsMap[category] || [];
  const isExtendedDetailsCompleted = checkFieldsFilled(extendedDetailsFields);

  return {
    details: isDetailsCompleted,
    extendedDetails: isExtendedDetailsCompleted,
  };
};

// Example Map for dynamic tab configuration
const tabConfigMap = new Map();
// Helper function to create a unique key for the map
const createKey = (category, onboardingType, jobType = DEFAULT_JOB_TYPE) =>
  `${category}_${onboardingType}_${jobType}`;

// Populate the map with configurations
tabConfigMap.set(
  createKey('childcare', 'serviceListing', 'part_time'),
  TABS_CHILDCARE.filter(item => JOB_TYPE_SERVICE_TABS['part_time'].includes(item))
);
tabConfigMap.set(createKey('childcare', 'serviceListing', 'full_time'), TABS_CHILDCARE);
tabConfigMap.set(
  createKey('childcare', 'jobListing', 'part_time'),
  TABS_CHILDCARE_PARENT.filter(item => JOB_TYPE_JOB_TABS['part_time'].includes(item))
);
tabConfigMap.set(createKey('childcare_full_time', 'serviceListing'), TABS_CHILDCARE);
tabConfigMap.set(createKey('childcare_full_time', 'jobListing'), TABS_CHILDCARE_PARENT);
tabConfigMap.set(createKey('childcare', 'jobListing', 'full_time'), TABS_CHILDCARE_PARENT);

tabConfigMap.set(createKey('tutor', 'serviceListing'), TABS_TUTOR);
tabConfigMap.set(createKey('tutor', 'jobListing'), TABS_TUTOR_PARENT);

tabConfigMap.set(createKey('pregnancy_and_birth', 'serviceListing'), TABS_PREGNANCY_AND_BIRTH);
tabConfigMap.set(createKey('pregnancy_and_birth', 'jobListing'), TABS_PREGNANCY_AND_BIRTH_PARENT);

tabConfigMap.set(createKey('maternity_nurse', 'serviceListing'), TABS_PREGNANCY_AND_BIRTH);
tabConfigMap.set(createKey('maternity_nurse', 'jobListing'), TABS_PREGNANCY_AND_BIRTH_PARENT);

tabConfigMap.set(createKey('activity_based', 'serviceListing'), TABS_ACTIVITY_BASED);
tabConfigMap.set(createKey('activity_based', 'jobListing'), TABS_ACTIVITY_BASED_PARENT);

tabConfigMap.set(createKey('creche', 'serviceListing'), TABS_CRECHE);
tabConfigMap.set(createKey('creche', 'jobListing'), TABS_CRECHE_PARENT);

// Function to get relevant tabs based on category, onboardingType, and jobType
export const getRelevantTabs = (category, onboardingType, jobType) => {
  const key = createKey(category, onboardingType, jobType);
  return tabConfigMap.get(key) || tabConfigMap.get(createKey(category, onboardingType)) || [];
};

/**
 * Refactored progressListing function
 */
export const progressListing = (listing, config, values = {}) => {
  if (!listing) throw new Error(`Invalid listing or listing is null.`);

  const { category, onboardingType, jobType, ...rest } = values?.publicData || {};
  const { availabilityPlan, price, publicData: formValues } = values || {};

  const publicData = listing?.attributes.publicData || {};

  /*   const listingFieldsConfig = config.listing.listingFields;
      const filteredFields = listingFieldsConfig.filter(
        field =>
          field.includeForListingTypes &&
          field.includeForListingTypes.includes(publicData?.listingType)
      ); */

  const images = listing?.images || [];
  const priceAmount = price?.amount || listing?.attributes.price?.amount || 0;
  const availabilityEntries =
    availabilityPlan?.entries || listing?.attributes.availabilityPlan?.entries;
  const hasAvailabilityException = listing?.hasAvailabilityException || false;
  const categorySelected = category || publicData?.category;
  const onboardingTypeSelected = onboardingType || publicData?.onboardingType;
  // If jobType is not 'full-time', treat it as 'part-time'
  const validJobType = jobType || publicData?.jobType;
  const jobTypeSelected = validJobType === 'full_time' ? 'full_time' : 'part_time';

  // Get the relevant tabs based on the category and onboardingType
  const relevantTabs = getRelevantTabs(
    categorySelected,
    onboardingTypeSelected,
    jobTypeSelected
  ).filter(tab => tab !== 'jobFilters');

  // Get completion status for 'Details' and 'Extended Details' tabs
  const { details, extendedDetails } = checkTabCompletion(
    formValues,
    publicData,
    categorySelected,
    onboardingTypeSelected,
    jobTypeSelected
  );

  // Construct the progressInfo object
  const progressInfo = relevantTabs.reduce((acc, tab) => {
    if (tab === 'details') {
      acc[tab] = details;
    } else if (tab === 'extendedDetails') {
      acc[tab] = extendedDetails;
    } else if (tab === 'photos') {
      acc[tab] = images.length > 0; // Check if there are images for the 'Photos' tab
    } else if (tab === 'pricing') {
      acc[tab] = priceAmount > 0;
    } else if (tab === 'availability') {
      acc[tab] =
        (Array.isArray(availabilityEntries) && availabilityEntries.length > 0) ||
        hasAvailabilityException;
    } else if (rest[tab] !== undefined && rest[tab] !== null) {
      acc[tab] = !!rest[tab];
    } else {
      acc[tab] = !!publicData[tab];
    }
    return acc;
  }, {});

  // Count completed tabs, excluding 'jobFilters'
  const tabsCompleted = relevantTabs.reduce((count, tab) => count + (progressInfo[tab] ? 1 : 0), 0);

  // Calculate the percentage of completed tabs, excluding 'jobFilters'
  const percentageCompleted =
    relevantTabs.length > 0 ? ((tabsCompleted / relevantTabs.length) * 100).toFixed(0) : 0;

  // Construct the final progress object
  const finalProgress = {
    progressInfo, // Detailed info about each tab's completion status
    tabsCompleted, // Total count of completed tabs
    percentageCompleted, // Overall completion percentage
    tabs: relevantTabs, // List of relevant tabs for the current category and onboarding type
  };

  // Return the final progress object
  return finalProgress;
};

export function calculateCompletionProgress(listing, fieldsConfig) {
  const publicData = listing?.attributes?.publicData || {};
  const listingType = publicData.listingType;
  const onboardingType = publicData.onboardingType;

  // Mandatory fields for all listing types
  const mandatoryFields = ['location', 'photos', 'title', 'description'];

  // Additional mandatory fields for serviceListing onboarding type
  if (onboardingType === 'serviceListing') {
    mandatoryFields.push('price', 'availability');
  }

  // Check if mandatory fields are present and valid
  const mandatoryFieldChecks = {
    price: listing?.attributes?.price?.amount > 0,
    location: !!publicData?.location,
    photos: (listing?.images || []).length > 0,
    availability: !!(listing?.attributes?.availabilityPlan?.entries || []).length,
    title: !!listing?.attributes?.title,
    description: !!listing?.attributes?.description,
    // Add check for startDateJob if startEndDateJob is a required field
    startEndDateJob: !!publicData.startDateJob,
  };

  // Filter the fields based on listingType
  const relevantFields = fieldsConfig.filter(field => {
    const { listingTypeConfig, key } = field;
    const listingTypeIds = listingTypeConfig?.listingTypeIds || [];
    const limitToListingTypeIds = listingTypeConfig?.limitToListingTypeIds;

    // Check if listingType matches or is empty
    return limitToListingTypeIds && listingTypeIds.includes(listingType);
  });

  // Count the relevant fields that are present and have non-empty values in publicData
  const filledFields = relevantFields.filter(field => {
    const { key } = field;
    const value = publicData[key];

    // Special case for startEndDateJob
    if (key === 'startEndDateJob') {
      return (
        publicData.hasOwnProperty('startDateJob') &&
        publicData.startDateJob !== null &&
        publicData.startDateJob !== undefined &&
        publicData.startDateJob !== ''
      );
    }

    // Ensure the field is present and not empty, null, or undefined
    return publicData.hasOwnProperty(key) && value !== null && value !== undefined && value !== '';
  });

  // Calculate the number of completed mandatory fields
  const completedMandatoryFields = mandatoryFields.filter(field => mandatoryFieldChecks[field]);

  // Total number of relevant fields including mandatory fields
  const totalRelevantFields = relevantFields.length + mandatoryFields.length;

  // Total number of filled fields including mandatory fields
  const totalFilledFields = filledFields.length + completedMandatoryFields.length;

  // Calculate the percentage of completion
  const completionPercentage = (totalFilledFields / totalRelevantFields) * 100;

  // Detailed progress information for each tab
  const progressInfo = {
    ...mandatoryFields.reduce((acc, field) => {
      acc[field] = mandatoryFieldChecks[field];
      return acc;
    }, {}),
    ...relevantFields.reduce((acc, field) => {
      const key = field.key;

      // Special case for startEndDateJob
      if (key === 'startEndDateJob') {
        acc[key] =
          publicData.hasOwnProperty('startDateJob') &&
          publicData.startDateJob !== null &&
          publicData.startDateJob !== undefined &&
          publicData.startDateJob !== '';
      } else {
        acc[key] =
          publicData.hasOwnProperty(key) &&
          publicData[key] !== null &&
          publicData[key] !== undefined &&
          publicData[key] !== '';
      }

      return acc;
    }, {}),
  };

  // Construct the final progress object
  const finalProgress = {
    progressInfo, // Detailed info about each field's completion status
    tabsCompleted: totalFilledFields, // Total count of completed fields (including mandatory and relevant)
    percentageCompleted: completionPercentage.toFixed(2), // Overall completion percentage
  };

  return finalProgress;
}
