import React, { useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';

// Import shared components
import { H3, ListingLink } from '../../../../components';

// Import Custom components
import { MIN_PHOTOS_MINDER_LISTING } from '../../../../config/configCustomListing';

// Import modules from this directory
import EditListingPhotosForm from './EditListingPhotosForm';
import css from './EditListingPhotosPanel.module.css';
import ModalListingCompleted from '../../../../components/ModalListingCompleted/ModalListingCompleted';
import { USER_TYPE_PARENT } from '../../../../config/configCustomUser';

const getInitialValues = params => {
  const { images } = params;
  return { images };
};

const EditListingPhotosPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    listing,
    onImageUpload,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSubmit,
    onRemoveImage,
    listingImageConfig,
    currentUserRole,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;
  const isParent = currentUserRole === USER_TYPE_PARENT;

  return (
    <div className={classes}>
      <div className={props.containerTopPanelClassName}>
        <H3 as="h1">
          {isPublished ? (
            <FormattedMessage
              id="EditListingPhotosPanel.title"
              values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
            />
          ) : (
            <FormattedMessage
              id="EditListingPhotosPanel.createListingTitle"
              values={{ lineBreak: <br /> }}
            />
          )}
        </H3>
        {props.progressBarComponent}
      </div>
      <EditListingPhotosForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={getInitialValues(props)}
        onImageUpload={onImageUpload}
        onSubmit={values => {
          const { addImage, ...updateValues } = values;

          const updateMoreValues = {
            ...updateValues,
          };
          onSubmit(updateMoreValues);
          setModalOpen(true);
        }}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        listingImageConfig={listingImageConfig}
        /* custom */
        minPhotos={MIN_PHOTOS_MINDER_LISTING}
        isParent={isParent}
      />
    </div>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default EditListingPhotosPanel;
