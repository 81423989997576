import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../../../../util/uiHelpers';
import { PopupButton } from '@typeform/embed-react';
import InfoModal from '../../../../../components/InfoModal/InfoModal';
import InfoButton from '../../../../../components/InfoButton/InfoButton';

import childcareImage from './images/childcareV2.svg';
import onlineImage from './images/onlineV2.svg';
import pregnancyImage from './images/maternityV3.svg';

import css from './GroupCategories.module.css';
import { Field } from 'react-final-form';
import { ONBOARDING_TYPE_SERVICE } from '../../../../../config/configCustomListing';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const GroupCategories = props => {
  const { rootClassName, className, form, validate, onboardingType } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [isInfoModalOpen, setInfoModal] = useState(false);
  const [getInfoSelected, changeInfoSelected] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleInfoModal = e => {
    setInfoModal(e);
  };

  const handleCategorySelection = categoryGroupId => {
    setSelectedCategory(categoryGroupId);
    form.change('categoryGroup', categoryGroupId);
    form.change('categoryLevel1', categoryGroupId);
  };

  const infoButton = selected => {
    return (
      <InfoButton
        className={css.infoButton}
        onClick={e => {
          e.preventDefault();
          changeInfoSelected(selected);
          setInfoModal(true);
        }}
      />
    );
  };

  const categoryLink = (name, image, categoryGroupId, categoryId, userType) => {
    const isSelected = categoryGroupId === selectedCategory;
    let imageClass = '';
    let buttonClass = '';

    if (selectedCategory !== null) {
      // Apply styles only if a selection has been made
      imageClass = isSelected ? '' : css.unselectedButton;
      buttonClass = isSelected ? css.selectedButton : '';
    }

    const nameText = <span className={css.locationName}>{name}</span>;
    return (
      <div
        name={categoryGroupId}
        className={classNames(css.location, buttonClass)}
        onClick={() => handleCategorySelection(categoryGroupId)}
      >
        <div className={css.imageWrapper}>
          <div className={css.aspectWrapper}>
            <LazyImage
              src={image}
              alt={name}
              className={classNames(css.locationImage, imageClass)}
            />
          </div>
        </div>
        <div className={css.linkText}>
          {nameText}
          {infoButton(categoryId, userType)}
        </div>
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className={css.description}>
        <h4>Select a Category:</h4>
      </div>

      <div className={css.locations}>
        <div className={css.wrapCategoryInfo}>
          {categoryLink(
            'Pregnancy, Birth and New Borns',
            pregnancyImage,
            onboardingType === ONBOARDING_TYPE_SERVICE
              ? 'group_pregnancy_birth'
              : 'group_pregnancy_birth_job',
            'pregnancy_and_birth'
          )}
        </div>
        <div className={css.wrapCategoryInfo}>
          {categoryLink(
            'Childcare & Daycare',
            childcareImage,
            onboardingType === ONBOARDING_TYPE_SERVICE ? 'group_childcare' : 'group_childcare_job',
            'childcare_daycare'
          )}
        </div>
        <div className={css.wrapCategoryInfo}>
          {categoryLink(
            'Tutor, Activities & Camps',
            onlineImage,
            onboardingType === ONBOARDING_TYPE_SERVICE
              ? 'group_tutor_activities'
              : 'group_tutor_activities_job',
            'tutor_activities'
          )}
        </div>
      </div>
      <InfoModal
        isInfoModalOpen={isInfoModalOpen}
        onOpenInfoModal={handleInfoModal}
        infoSelected={getInfoSelected}
        onboardingType={onboardingType}
      />
      <Field name="categoryGroup" validate={validate} component="input" type="hidden" />
    </div>
  );
};

GroupCategories.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

GroupCategories.propTypes = {
  rootClassName: string,
  className: string,
};

export default GroupCategories;
