import React, { useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { compose } from 'redux';
import { ARRAY_ERROR } from 'final-form';
import { Form as FinalForm, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage, intlShape, injectIntl } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { nonEmptyArray, composeValidators } from '../../../../util/validators';
import { isUploadImageOverLimitError } from '../../../../util/errors';

// Import shared components
import { Button, Form, AspectRatioWrapper } from '../../../../components';
import ImagePicker from '../../../../components/ImagePicker/ImagePicker';

// Import modules from this directory
import ListingImage from './ListingImage';
import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';

const ImageUploadError = props => {
  return props.uploadOverLimit ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
    </p>
  ) : props.uploadImageError ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
    </p>
  ) : null;
};

// NOTE: PublishListingError and ShowListingsError are here since Photos panel is the last visible panel
// before creating a new listing. If that order is changed, these should be changed too.
// Create and show listing errors are shown above submit button
const PublishListingError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
    </p>
  ) : null;
};

const ShowListingsError = props => {
  return props.error ? (
    <p className={css.error}>
      <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
    </p>
  ) : null;
};

// Field component that uses file-input to allow user to select images.
export const FieldAddImage = props => {
  const { formApi, onImageUploadHandler, aspectWidth = 1, aspectHeight = 1, ...rest } = props;
  const [isCropOpen, setIsCropOpen] = useState(false);
  return (
    <Field form={null} {...rest}>
      {fieldprops => {
        const { accept, input, label, disabled: fieldDisabled } = fieldprops;
        const { name, type } = input;
        const onChange = e => {
          const file = e;
          formApi.change(`addImage`, file);
          formApi.blur(`addImage`);
          onImageUploadHandler(file);
          setIsCropOpen(false);
        };
        const onCancel = () => {
          setIsCropOpen(false);
        };
        const inputProps = { accept, id: name, name, onChange, type };
        const otherProps = { label, onCancel };
        return (
          <div className={classNames(css.addImageWrapper, !isCropOpen ? '' : css.addControls)}>
            <AspectRatioWrapper width={aspectWidth} height={aspectHeight}>
              {fieldDisabled ? null : (
                <ImagePicker
                  inputProps={inputProps}
                  {...otherProps}
                  classNameLabel={css.addImage}
                  onCropOpen={setIsCropOpen}
                />
              )}
            </AspectRatioWrapper>
          </div>
        );
      }}
    </Field>
  );
};

// Component that shows listing images from "images" field array
const FieldListingImage = props => {
  const { name, intl, onRemoveImage, aspectWidth, aspectHeight, variantPrefix } = props;
  return (
    <Field name={name}>
      {fieldProps => {
        const { input } = fieldProps;
        const image = input.value;
        return image ? (
          <ListingImage
            image={image}
            key={image?.id?.uuid || image?.id}
            className={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={() => onRemoveImage(image?.id)}
            aspectWidth={aspectWidth}
            aspectHeight={aspectHeight}
            variantPrefix={variantPrefix}
          />
        ) : null;
      }}
    </Field>
  );
};

export const EditListingPhotosFormComponent = props => {
  const [state, setState] = useState({ imageUploadRequested: false });
  const [submittedImages, setSubmittedImages] = useState([]);
  const [sortedImages, setSortedImages] = useState(props.initialValues.images);
  const initialImages = props.initialValues.images;
  const isSortingImages =
    sortedImages.length === initialImages.length && !isEqual(sortedImages, initialImages);

  const onImageUploadHandler = file => {
    const { listingImageConfig, onImageUpload } = props;
    if (file) {
      setState({ imageUploadRequested: true });

      onImageUpload({ id: `${file.name}_${Date.now()}`, file }, listingImageConfig)
        .then(() => {
          setState({ imageUploadRequested: false });
        })
        .catch(() => {
          setState({ imageUploadRequested: false });
        });
    }
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize={isSortingImages} // Set keepDirtyOnReinitialize based on the drag and drop state
      render={formRenderProps => {
        const {
          form,
          className,
          fetchErrors,
          handleSubmit,
          intl,
          invalid,
          onRemoveImage,
          disabled,
          ready,
          saveActionMsg,
          updated,
          updateInProgress,
          touched,
          errors,
          values,
          listingImageConfig,
          /* custom */
          minPhotos,
          isParent,
        } = formRenderProps;

        const images = values.images;
        const { aspectWidth = 1, aspectHeight = 1, variantPrefix } = listingImageConfig;

        const { publishListingError, showListingsError, updateListingError, uploadImageError } =
          fetchErrors || {};
        const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

        // imgs can contain added images (with temp ids) and submitted images with uniq ids.
        const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
        const imageIdsFromProps = arrayOfImgIds(images);
        const imageIdsFromPreviousSubmit = arrayOfImgIds(submittedImages);
        const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
        const submittedOnce = submittedImages.length > 0;
        const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

        //set min photos requirement
        const isNotMinimumImageRequired =
          (imageIdsFromPreviousSubmit.length > 0 &&
            imageIdsFromPreviousSubmit.length < minPhotos) ||
          (imageIdsFromProps.length > 0 && imageIdsFromProps.length < minPhotos);

        const submitReady = (updated && pristineSinceLastSubmit) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          state.imageUploadRequested ||
          ready ||
          isNotMinimumImageRequired;
        const imagesError = touched.images && errors?.images && errors.images[ARRAY_ERROR];

        const classes = classNames(css.root, className);

        return (
          <Form
            className={classes}
            onSubmit={e => {
              setSubmittedImages(images);
              handleSubmit(e);
              setSortedImages(images);
            }}
          >
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.updateFailed" />
              </p>
            ) : null}

            <div className={css.imagesFieldArray}>
              <FieldArray
                name="images"
                validate={composeValidators(
                  nonEmptyArray(
                    intl.formatMessage({
                      id: 'EditListingPhotosForm.imageRequired',
                    })
                  )
                )}
              >
                {({ fields }) => {
                  const [dragImageIndex, setDragImageIndex] = useState(null);

                  const handleDragStart = (e, index) => {
                    setDragImageIndex(index);
                  };
                  const handleDragOver = e => {
                    e.preventDefault();
                  };
                  const handleDrop = (e, dropIndex) => {
                    e.preventDefault();
                    if (dragImageIndex === dropIndex) {
                      return; // Return early if the dragged image index is the same as the drop index
                    }
                    const updatedImages = [...fields.value]; // Create a copy of the images array in fields
                    const draggedImage = updatedImages[dragImageIndex]; // Get the dragged image
                    updatedImages.splice(dragImageIndex, 1); // Remove the dragged image from its original position
                    updatedImages.splice(dropIndex, 0, draggedImage); // Insert the dragged image at the drop position
                    /* fields.move(dragImageIndex, dropIndex); */
                    form.change('images', updatedImages);
                    setSortedImages(updatedImages);
                  };
                  return fields.map((name, index) => (
                    <div
                      key={`${index}-image`}
                      draggable
                      onDragStart={e => handleDragStart(e, index)}
                      onDragOver={e => handleDragOver(e)}
                      onDrop={e => handleDrop(e, index)}
                    >
                      <FieldListingImage
                        key={name}
                        name={name}
                        onRemoveImage={imageId => {
                          fields.remove(index);
                          onRemoveImage(imageId);
                        }}
                        intl={intl}
                        aspectWidth={aspectWidth}
                        aspectHeight={aspectHeight}
                        variantPrefix={variantPrefix}
                      />
                    </div>
                  ));
                }}
              </FieldArray>

              <FieldAddImage
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                label={
                  <span className={css.chooseImageText}>
                    <span className={css.chooseImage}>
                      <FormattedMessage id="EditListingPhotosForm.chooseImage" />
                    </span>
                    <span className={css.imageTypes}>
                      <FormattedMessage id="EditListingPhotosForm.imageTypes" />
                    </span>
                  </span>
                }
                type="file"
                disabled={state.imageUploadRequested}
                formApi={form}
                onImageUploadHandler={onImageUploadHandler}
                aspectWidth={aspectWidth}
                aspectHeight={aspectHeight}
                /* custom */
                imgState={state}
              />
            </div>

            {imagesError ? <div className={css.arrayError}>{imagesError}</div> : null}

            <ImageUploadError
              uploadOverLimit={uploadOverLimit}
              uploadImageError={uploadImageError}
            />

            <p className={css.tip}>
              <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
            </p>

            <PublishListingError error={publishListingError} />
            <ShowListingsError error={showListingsError} />

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {/*  {isParent && saveActionMsg !== 'Save changes' ? 'Find Minders™' : saveActionMsg} */}
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
  listingImageConfig: object.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
