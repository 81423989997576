import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../../../util/types';
import { isEmptyStringOrMinLength } from '../../../../util/validators';
import setFieldData from 'final-form-set-field-data';
import { Form, Button, FieldTextbox } from '../../../../components';

import css from './EditListingCustomTextForm.module.css';
import { getMinCharacters } from '../../../../config/configCustomListing';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const EditListingCustomTextFormComponent = props => (
  <FinalForm
    mutators={{ setFieldData }}
    {...props}
    render={formRenderProps => {
      const {
        form,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        formConfig,
      } = formRenderProps;
      const TAB = formConfig.TAB;
      const MIN_LENGTH = getMinCharacters(TAB);

      const labelMessage = intl.formatMessage({
        id: formConfig.labelMessageId,
      });
      const placeholderMessage = intl.formatMessage({
        id: formConfig.placeholderMessageId,
      });
      const minLengthMessage = intl.formatMessage(
        { id: 'EditListingCustomTextForm.minLength' },
        {
          minLength: MIN_LENGTH,
        }
      );
      const minLength100Message = isEmptyStringOrMinLength(minLengthMessage, MIN_LENGTH);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCustomTextForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCustomTextForm.showListingFailed" />
        </p>
      ) : null;

      //isLowerMinCharacter is used to disable or enable button.
      const [isLowerMinCharacter, setIsLowerMinCharacter] = useState();
      const [textBoxValues, setTextBoxValues] = useState({ [TAB]: values[TAB] });
      const [isEmpty, setIsEmpty] = useState(values[TAB]?.length > 0 ? false : true);

      // "Saving" is shown when form is submited.
      const SavingIndicator = ({ name }) => (
        <Field
          name={name}
          subscription={{ data: true }}
          render={({
            meta: {
              data: { saving },
            },
          }) => {
            return saving ? <div className={css.saving}>Saving </div> : null;
          }}
        />
      );

      //Check if click event is outside the form. If so, it allows to submit changes.
      const handleBlur = async e => {
        const canSubmit = !e.currentTarget.contains(e.relatedTarget);
        const isButton = !!(e.relatedTarget && e.relatedTarget.id == 'customTextFormSaveButton');
        if (canSubmit && !isButton && !isLowerMinCharacter) {
          const value = e?.target?.value;
          if (!!textBoxValues && textBoxValues[TAB] !== value) {
            // values have changed
            setTextBoxValues({ [TAB]: value });
            form.mutators.setFieldData(TAB, { saving: true });
            await props.onTextChange({ [TAB]: value });
            await sleep(2000);
            form.mutators.setFieldData(TAB, { saving: false });
          }
        }
      };

      //Check if textbox contains minimun characters and set SetIsMinCharacter.
      const handleChange = e => {
        e.preventDefault();
        const eLength = e?.target?.value.length;
        const isLowerMinimum = eLength < MIN_LENGTH && eLength > 0;
        setIsLowerMinCharacter(isLowerMinimum);
        setIsEmpty(eLength > 0 ? false : true);
      };

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || isLowerMinCharacter;

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
          /*         onBlur={handleBlur}*/
          onChange={handleChange}
        >
          {errorMessage}
          {errorMessageShowListing}

          <FieldTextbox
            id={TAB}
            name={TAB}
            className={css.textBox}
            errorLabelClassName={css.errorFieldLabel}
            minCharactersLabelClassName={css.minCharactersLabel}
            label={labelMessage}
            placeholder={placeholderMessage}
            validate={minLength100Message}
            minCharacters={!isEmpty ? MIN_LENGTH : null}
          />
          <Button
            id="customTextFormSaveButton"
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
            tabIndex="1"
          >
            {saveActionMsg}
          </Button>
          <SavingIndicator name={TAB} />
        </Form>
      );
    }}
  />
);

EditListingCustomTextFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingCustomTextFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  // isMinRequired: bool.isRequired,
};

const EditListingCustomTextForm = compose(injectIntl)(EditListingCustomTextFormComponent);

export default EditListingCustomTextForm;
