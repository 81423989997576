export const getTextId = (initialMessageId, listingType, intl) => {
  const specificMessageId = `${initialMessageId}_${listingType}`;

  // Return the specific message ID if it exists in the internationalization system
  if (intl.messages[specificMessageId]) {
    return specificMessageId;
  }

  // Return the initial message ID if the specific one doesn't exist
  return initialMessageId;
};
