import React from 'react';
import { FieldDateInput, FieldSelect } from '../../../../components';
import { required, composeValidators } from '../../../../util/validators';
import css from './CustomTimeFrameAvailableField.module.css';
import {
  getDefaultTimeZoneOnBrowser,
  initialVisibleMonth,
  pastIsOutsideRange,
} from '../../../../util/dates';
import moment from 'moment';

export const TIMEFRAMEARRAY = [...Array(12)].map((_, i) => {
  return i === 0
    ? { key: `${i + 1}_week`, label: `${i + 1} week` }
    : { key: `${i + 1}_weeks`, label: `${i + 1} weeks` };
});

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// React-dates returns wrapped date objects
const extractDateFromFieldDateInput = dateValue =>
  dateValue && dateValue.date ? dateValue.date : null;

const CustomTimeFrameAvailableField = props => {
  const { intl, values, form } = props;

  const timeFrameLabel = 'Weeks Available:';
  const timeFramePlaceholder = 'Select...';
  const durationRequiredMessage = 'Required';

  const TODAY = new Date();
  const startDateJob = values.startDateJob;
  const startDateJobDay = extractDateFromFieldDateInput(startDateJob) || TODAY;

  const currentMonth = moment(TODAY).add(1, 'd');
  const displayFormat = 'MMMM D, YYYY';
  const dateFormattingOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  const isOutsideRange = startDateJobDay => day => {
    if (!values.timeFrame) {
      const endDate = moment(startDateJobDay).add(12, 'weeks');
      return day.isBefore(startDateJobDay, 'day') || day.isAfter(endDate, 'day');
    }
    const weeks = parseInt(values.timeFrame.split('_')[0], 10);
    const endDate = moment(startDateJobDay).add(weeks, 'weeks');
    return day.isBefore(TODAY, 'day') || day.isAfter(endDate, 'day');
  };
  const startDateJobMessage = 'Start Date:';
  const endDateJobMessage = 'End Date:';

  const calculateEndDate = startDateJobDay => {
    const weeks = values.timeFrame ? parseInt(values.timeFrame?.split('_')[0], 10) : 1;
    const endDate = {
      date: moment(startDateJobDay.date)
        .add(weeks, 'weeks')
        .toDate(),
    };

    form.change('endDateJob', endDate);
  };

  return (
    <div className={css.timeFrameContainer}>
      <div className={css.timeFrameWrapper}>
        <FieldSelect
          className={css.timerFrameField}
          name="timeFrame"
          id="timeFrame"
          label={timeFrameLabel}
          validate={composeValidators(required(durationRequiredMessage))}
          onChange={() => form.change('endDateJob', null)}
        >
          <option disabled value="">
            {timeFramePlaceholder}
          </option>
          {TIMEFRAMEARRAY.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
      </div>
      <div className={css.startEndDateJob}>
        <FieldDateInput
          className={css.fieldDateInput}
          name="startDateJob"
          id="startDateJob"
          date={TODAY}
          label={startDateJobMessage}
          placeholderText={intl.formatDate(TODAY, dateFormattingOptions)}
          useMobileMargins
          showErrorMessage={false}
          displayFormat={displayFormat}
          required={true}
          isOutsideRange={pastIsOutsideRange}
          onChange={calculateEndDate}
        />
        <FieldDateInput
          className={css.fieldDateInput}
          name="endDateJob"
          id="endDateJob"
          label={endDateJobMessage}
          placeholderText={intl.formatDate(startDateJobDay, dateFormattingOptions)}
          initialVisibleMonth={initialVisibleMonth(startDateJobDay, defaultTimeZone())}
          useMobileMargins
          showErrorMessage={false}
          displayFormat={displayFormat}
          required={true}
          isOutsideRange={isOutsideRange(startDateJobDay)}
        />
      </div>
    </div>
  );
};

export default CustomTimeFrameAvailableField;
