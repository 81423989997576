import React from 'react';
import { array, shape, string } from 'prop-types';
import { FieldDateInput } from '../../../../components';

import { intlShape } from '../../../../util/reactIntl';
//reactIntl';
import { propTypes } from '../../../../util/types';
import {
  isDateSameOrAfter,
  initialVisibleMonth,
  getDefaultTimeZoneOnBrowser,
  pastIsOutsideRange,
} from '../../../../util/dates';
import moment from 'moment';

import css from './CustomStartEndJobField.module.css';

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

// React-dates returns wrapped date objects
const extractDateFromFieldDateInput = dateValue =>
  dateValue && dateValue.date ? dateValue.date : null;

const getTextIds = (listingType, intl) => {
  const defaultLabelId = `EditListingDetailsForm.startEndDateJob.label`;
  const defaultStartLabelId = `EditListingDetailsForm.startEndDateJob.startLabel`;
  const defaultEndLabelId = `EditListingDetailsForm.startEndDateJob.endLabel`;

  let labelId = defaultLabelId;
  let startLabelId = defaultStartLabelId;
  let endLabelId = defaultEndLabelId;

  const specificMessageId = `${defaultLabelId}_${listingType}`;
  const specificStartMessageId = `${defaultStartLabelId}_${listingType}`;
  const specificEndMessageId = `${defaultEndLabelId}_${listingType}`;

  // Check if the specific IDs exist in the internationalization system
  if (intl.messages[specificMessageId]) {
    labelId = specificMessageId;
  }
  if (intl.messages[specificStartMessageId]) {
    startLabelId = specificStartMessageId;
  }
  if (intl.messages[specificEndMessageId]) {
    endLabelId = specificEndMessageId;
  }

  return { labelId, startLabelId, endLabelId };
};

const CustomStartEndJobField = props => {
  const { intl, values } = props;

  const listingType = values?.listingType;

  const startEndDateJobLabel = intl.formatMessage({
    id: getTextIds(listingType, intl).labelId,
  });

  const startDateJobMessage = intl
    .formatMessage({
      id: getTextIds(listingType, intl).startLabelId,
    })
    .trim();
  const endDateJobMessage = intl
    .formatMessage({
      id: getTextIds(listingType, intl).endLabelId,
    })
    .trim();

  const startDateJob = values.startDateJob;
  const startDateJobDay = extractDateFromFieldDateInput(startDateJob);
  const TODAY = new Date();
  const nextMonth = moment(TODAY).add(1, 'M');
  const displayFormat = 'MMMM D, YYYY';
  const dateFormattingOptions = { day: 'numeric', month: 'long', year: 'numeric' };

  const isOutsideRange = startDateJobDay => day => {
    const endDate = moment(startDateJobDay).add(1, 'M');
    return !isDateSameOrAfter(day, endDate);
  };

  return (
    <div>
      <label>
        <span>{startEndDateJobLabel}</span>
      </label>
      <div className={css.startEndDateJob}>
        <FieldDateInput
          className={css.fieldDateInput}
          name="startDateJob"
          id="startDateJob"
          label={startDateJobMessage}
          placeholderText={intl.formatDate(TODAY, dateFormattingOptions)}
          useMobileMargins
          showErrorMessage={false}
          displayFormat={displayFormat}
          required={true}
          isOutsideRange={pastIsOutsideRange}
        />
        <FieldDateInput
          className={css.fieldDateInput}
          name="endDateJob"
          id="endDateJob"
          label={endDateJobMessage}
          placeholderText={intl.formatDate(nextMonth, dateFormattingOptions)}
          /*     initialDate={nextMonth} */
          initialVisibleMonth={initialVisibleMonth(nextMonth, defaultTimeZone())}
          useMobileMargins
          showErrorMessage={false}
          displayFormat={displayFormat}
          required={true}
          isOutsideRange={isOutsideRange(startDateJobDay)}
        />
      </div>
    </div>
  );
};

CustomStartEndJobField.defaultProps = {
  className: null,
  rootClassName: null,
  fetchErrors: null,
  formId: null,
  availabilityExceptions: [],
};

CustomStartEndJobField.propTypes = {
  className: string,
  rootClassName: string,
  formId: string,
  availabilityExceptions: array,
  intl: intlShape.isRequired,

  fetchErrors: shape({
    updateListingError: propTypes.error,
  }),
};

export default CustomStartEndJobField;
