import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import css from './ImagePicker.module.css';
import CropperTool from './Cropper';

const ImagePicker = props => {
  const { inputProps, circular = false, label, onCancel, classNameLabel, onCropOpen } = props;
  const [imageUrl, setImageUrl] = useState();

  const { onChange, name } = inputProps;

  const onImageChange = event => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = event => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
          const MAX_WIDTH = 1500; // Set the maximum desired width
          const MAX_HEIGHT = 1125; // Set the maximum desired height
          let quality = 1; // Set the desired image quality (0.9 means 90% quality)
          let width = image.width;
          let height = image.height;

          // Perform resizing calculations
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            quality = 0.9;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, width, height);
          canvas.toBlob(
            blob => {
              // Check the file size before uploading
              if (blob.size <= 20 * 1024 * 1024) {
                // 20MB in bytes
                const resizedImageUrl = URL.createObjectURL(blob);
                setImageUrl(resizedImageUrl);
              } else {
                // File size exceeds the limit, handle accordingly
                console.log('File size exceeds the limit (20MB)');
                // Reset the input value to allow selecting a different file
                event.target.value = '';
              }
            },
            file.type,
            quality
          );
        };
      };

      reader.readAsDataURL(file);
      event.target.value = '';
      onCropOpen(true);
    }
  };

  const onCancelClick = e => {
    e.stopPropagation();
    setImageUrl(null);
    onCancel(e);
  };

  const shapeImageLabel = circular ? css.addImageCircular : classNameLabel;

  return (
    <div className={css.container}>
      <div className={`${circular ? css.pickerWrapperCircular : null}`}>
        <div className={`${circular ? css.pickerContainerCircular : null}`}>
          <input {...inputProps} className={css.addImageInput} onChange={onImageChange} />
          {!imageUrl ? (
            <label htmlFor={name} className={shapeImageLabel}>
              {label}
            </label>
          ) : null}
        </div>

        <CropperTool
          image={imageUrl}
          circular={circular}
          onChangeImage={onChange}
          onCancelClick={onCancelClick}
        />
      </div>
    </div>
  );
};

ImagePicker.defaultProps = {
  onSave: null,
  cropCircular: false,
};

ImagePicker.propTypes = {
  onSave: func,
  cropCircular: bool,
};

export default ImagePicker;
