import React, { useState } from 'react';
import classNames from 'classnames';
import css from './AvailabilityTable.module.css';

const AvailabilityTableDaily = ({ data, className }) => {
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const classNameDefault = classNames(className, css.table);

  return (
    <table className={classNameDefault}>
      <thead>
        <tr>
          <th className={classNames(css.header, css.firstColumn, css.whiteCell)}></th>
          {daysOfWeek.map(day => (
            <th key={day} className={`${css.header} ${css.otherColumns}`}>
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={`${css.cell} ${css.firstColumn}`}>Availability</td>
          {daysOfWeek.map(day => {
            const isAvailable = data.some(period => period.days[day] && period.days[day].length);
            return (
              <td
                key={day}
                className={classNames(
                  css.cellDaily,
                  css.withBorder,
                  isAvailable ? css.cellAvailable : css.cellUnavailable
                )}
              >
                {isAvailable ? <span>&#10004;</span> : ''}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default AvailabilityTableDaily;
