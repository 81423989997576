import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT, STOCK_INFINITE_ITEMS, STOCK_TYPES } from '../../../../util/types';
import { types as sdkTypes } from '../../../../util/sdkLoader';

// Import shared components
import { H3, ListingLink } from '../../../../components';

// Import modules from this directory
import { countryAbbreviations, countryNames } from '../../../../config/configCustomCurrency';
import { supportedCountries } from '../../../../config/configStripe';

import EditListingPackagesForm from './EditListingPackagesForm';
import css from './EditListingPackagesPanel.module.css';
import { priceData } from '../../../ListingPage/ListingPage.shared';
import { v4 as uuidv4 } from 'uuid';

const { Money } = sdkTypes;
const BILLIARD = 1000000000000000;
const currencyDefault = {
  countryCode: 'IE',
  currency: 'EUR',
};

const getListingTypeConfig = (publicData, listingTypes) => {
  const selectedListingType = publicData.listingType;
  return listingTypes.find(conf => conf.listingType === selectedListingType);
};

const extractCountry = address => {
  const parts = address.split(',').map(part => part.trim());
  return parts.length > 1 ? parts[parts.length - 1] : parts[0];
};

const findCurrencyDataForCountry = country => {
  // Convert abbreviation to full country name if applicable
  const fullCountryName = countryAbbreviations[country] || country;
  // Find the country code from countryNames
  const countryCode = Object.keys(countryNames).find(
    code => countryNames[code] === fullCountryName
  );
  // Find the currency for the found country code
  const countryData = supportedCountries.find(c => c.code === countryCode);
  // Return both currency and country code
  return countryData ? { currency: countryData.currency, countryCode: countryData.code } : null;
};
const getInitialValues = (params, currencyDataDefault) => {
  const { listing, listingTypes } = params;
  const { price, publicData } = listing?.attributes || {};
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;
  const currencyData = publicData?.currentCurrency || {};
  const currentStock = listing?.currentStock;
  const listingTypeConfig = getListingTypeConfig(publicData, listingTypes);
  const hasInfiniteStock = STOCK_INFINITE_ITEMS.includes(listingTypeConfig?.stockType);
  const currentStockQuantity = currentStock?.attributes?.quantity;
  const stock =
    currentStockQuantity != null
      ? currentStockQuantity
      : isPublished
      ? 0
      : hasInfiniteStock
      ? BILLIARD
      : 1;
  const stockTypeInfinity = [];
  const hasPackages = !!publicData?.packages && publicData?.packages?.length > 0;
  let packages = publicData?.packages;
  if (!hasPackages) {
    packages = [
      {
        countryCode: currencyDataDefault.countryCode,
        packageId: uuidv4(),
      },
    ];
  }
  const packagesWithMoneyPrice =
    hasPackages &&
    packages.map(pkg => ({
      ...pkg,
      price: pkg.price ? new Money(pkg.price.amount, pkg.price.currency) : null,
      stock: String(pkg.stock),
    }));

  const currentPackages = hasPackages ? packagesWithMoneyPrice : packages;

  const countryCode = currencyData?.countryCode || currencyDataDefault?.countryCode || 'IE';
  const currency = currencyData?.currency || currencyDataDefault?.currency || 'EUR';
  return {
    price,
    countryCode,
    currency,
    stock,
    stockTypeInfinity,
    packages: currentPackages,
  };
};

const EditListingPackagesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    marketplaceCurrency,
    listingMinimumPriceSubUnits,
    listingTypes,
    disabled,
    ready,
    onSubmit,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const { publicData } = listing?.attributes || {};
  const { location } = publicData || {};
  const { address } = location;
  const countryLocation = extractCountry(address);
  const countryDefault = countryLocation || 'Ireland';
  const currencyDataDefault = findCurrencyDataForCountry(countryDefault) || currencyDefault;

  // Extract the current currency and country code from the listing's public data
  const currentCurrency =
    publicData?.currentCurrency?.currency || currencyDataDefault?.currency || marketplaceCurrency;
  const currentCountryCode =
    publicData?.currentCurrency?.countryCode || currencyDataDefault?.countryCode || 'IE';

  // State for managing selected currency, initialized with current values
  const [selectedCurrency, setSelectedCurrency] = useState({
    currency: currentCurrency,
    countryCode: currentCountryCode,
  });

  // Function to handle changes in currency selection
  const handleCurrencyChange = currencyData => {
    setSelectedCurrency(currencyData);
  };
  const classes = classNames(rootClassName || css.root, className);
  /*   const initialValues = getInitialValues(props, currencyDataDefault); */
  const [initialValues, setInitialFormValues] = useState(() =>
    getInitialValues(props, currencyDataDefault)
  );
  // Form needs to know data from listingType
  const listingTypeConfig = getListingTypeConfig(publicData, listingTypes);

  const hasInfiniteStock = STOCK_INFINITE_ITEMS.includes(listingTypeConfig?.stockType);
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;

  const priceCurrencyValid =
    marketplaceCurrency && initialValues.price instanceof Money
      ? initialValues.price.currency === currentCurrency ||
        initialValues.price.currency === selectedCurrency.currency
      : !!marketplaceCurrency;
  const unitType = publicData.unitType;

  if (!priceCurrencyValid) {
    console.log('currency error');
  }

  return (
    <div className={classes}>
      <div className={props.containerTopPanelClassName}>
        <H3 as="h1">
          {isPublished ? (
            <FormattedMessage
              id="EditListingPackagesPanel.title"
              values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
            />
          ) : (
            <FormattedMessage
              id="EditListingPackagesPanel.createListingTitle"
              values={{ lineBreak: <br /> }}
            />
          )}
        </H3>
        {props.progressBarComponent}
      </div>
      {priceCurrencyValid ? (
        <EditListingPackagesForm
          className={css.form}
          initialValues={initialValues}
          onSubmit={values => {
            const { stockTypeInfinity, packages } = values;

            const totalStock = packages.reduce((sum, pkg) => sum + Number(pkg.stock || 0), 0);
            const lowestPackage = packages.reduce((lowest, pkg) => {
              if (!lowest.price || pkg.price.amount < lowest.price.amount) {
                return pkg;
              }
              return lowest;
            }, {});
            const price = lowestPackage?.price;
            const stock = totalStock;
            const currency = lowestPackage?.price?.currency;
            const countryCode = lowestPackage?.countryCode;

            const hasStockTypeInfinityChecked = stockTypeInfinity?.[0] === 'infinity';
            const hasNoCurrentStock = listing?.currentStock?.attributes?.quantity == null;
            const hasStockQuantityChanged = stock && stock !== initialValues.stock;
            const oldTotal = hasNoCurrentStock ? null : initialValues.stock;
            const stockUpdateMaybe =
              hasInfiniteStock && (hasNoCurrentStock || hasStockTypeInfinityChecked)
                ? {
                    stockUpdate: {
                      oldTotal,
                      newTotal: BILLIARD,
                    },
                  }
                : hasNoCurrentStock || hasStockQuantityChanged
                ? {
                    stockUpdate: {
                      oldTotal,
                      newTotal: stock,
                    },
                  }
                : {};
            const currentCurrency = {
              currency,
              countryCode,
            };

            const updatePackages = packages.map(pkg => ({
              ...pkg,
              price: { amount: pkg.price.amount, currency: pkg.price.currency },
            }));
            // New values for listing attributes
            const updateValues = {
              price,
              ...stockUpdateMaybe,
              publicData: { currentCurrency, packages: updatePackages },
            };
            setInitialFormValues({
              price,
              countryCode,
              currency,
              packages,

              price,
              stock: stockUpdateMaybe?.stockUpdate?.newTotal || stock,
              stockTypeInfinity,
            });
            onSubmit(updateValues);
          }}
          listingMinimumPriceSubUnits={listingMinimumPriceSubUnits}
          marketplaceCurrency={marketplaceCurrency}
          listingType={listingTypeConfig}
          unitType={unitType}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          /* custom */
          onCurrencyChange={handleCurrencyChange}
          selectedCurrency={selectedCurrency}
        />
      ) : (
        <div className={css.priceCurrencyInvalid}>
          <FormattedMessage
            id="EditListingPricingPanel.listingPriceCurrencyInvalid"
            values={{ marketplaceCurrency }}
          />
        </div>
      )}
    </div>
  );
};

const { func, object, string, bool, number, arrayOf, oneOf, shape } = PropTypes;

EditListingPackagesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPackagesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  marketplaceCurrency: string.isRequired,
  listingMinimumPriceSubUnits: number.isRequired,
  listingTypes: arrayOf(
    shape({
      stockType: oneOf(STOCK_TYPES),
    })
  ).isRequired,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPackagesPanel;
