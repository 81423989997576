import React, { useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// Import configs and util modules
import appSettings from '../../../../config/settings';
import { intlShape, injectIntl, FormattedMessage } from '../../../../util/reactIntl';
import { STOCK_INFINITE_ITEMS, STOCK_MULTIPLE_ITEMS, propTypes } from '../../../../util/types';
import { isOldTotalMismatchStockError } from '../../../../util/errors';
import { maxLength, minLength, required, composeValidators } from '../../../../util/validators';
import * as validators from '../../../../util/validators';
import { formatMoney } from '../../../../util/currency';
import { types as sdkTypes } from '../../../../util/sdkLoader';
import { v4 as uuidv4 } from 'uuid';

// Import shared components
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldTextInput,
  FieldTextbox,
  Modal,
} from '../../../../components';

// Import modules from this directory
import css from './EditListingPackagesForm.module.css';
import { supportedCountries } from '../../../../config/configStripe';
import { countryNames } from '../../../../config/configCustomCurrency';

const { Money } = sdkTypes;
const MILLION = 1000000;
const PACKAGE_NAME_MAX_LENGTH = 200;
const PACKAGE_DESCRIPTION_MIN_LENGTH = 10;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

const UpdateStockToInfinityCheckboxMaybe = ({ hasInfiniteStock, currentStock, formId, intl }) => {
  return hasInfiniteStock && currentStock != null && currentStock < MILLION ? (
    <div className={css.input}>
      <p>
        <FormattedMessage
          id="EditListingPricingAndStockForm.updateToInfiniteInfo"
          values={{
            currentStock,
            b: msgFragment => <b>{msgFragment}</b>,
          }}
        />
      </p>
      <FieldCheckboxGroup
        id={`${formId}.stockTypeInfinity`}
        name="stockTypeInfinity"
        options={[
          {
            key: 'infinity',
            label: intl.formatMessage({
              id: 'EditListingPricingAndStockForm.updateToInfinite',
            }),
          },
        ]}
        validate={validators.requiredFieldArrayCheckbox(
          intl.formatMessage({
            id: 'EditListingPricingAndStockForm.updateToInfiniteRequired',
          })
        )}
      />
    </div>
  ) : null;
};

export const EditListingPackagesFormComponent = props => {
  // Use state to track the selected currency
  return (
    <FinalForm
      {...props}
      mutators={{
        ...arrayMutators,
      }}
      render={formRenderProps => {
        const {
          formId,
          autoFocus,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          marketplaceCurrency,
          unitType,
          listingMinimumPriceSubUnits,
          listingType,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          /* custom */
          values,
          onCurrencyChange,
          selectedCurrency,
          form,
        } = formRenderProps;

        const [isDeletePackageModalOpen, setDeletePackageModal] = useState(false);

        const [packageToDelete, setPackageToDelete] = useState(null);

        const packagesValid = () => {
          const { values, errors } = form.getState();
          if (!values.packages || values.packages.length === 0) {
            return true; // No packages, thus not invalid (for Add Package button)
          }

          const packagesErrors = errors?.packages;
          if (packagesErrors && Object.keys(packagesErrors).length > 0) {
            return false; // Errors in packages, not valid
          }

          // Ensure every required field in each package is filled
          return values.packages.every(
            pkg => pkg.packageName && pkg.packageDescription && pkg.countryCode && pkg.price
          );
        };
        const formValid = packagesValid() && values.packages && values.packages.length > 0;
        const addPackageDisabled = !packagesValid(); // If any package is invalid, disable the add button

        const priceValidators = getPriceValidators(
          listingMinimumPriceSubUnits,
          selectedCurrency.currency,
          intl
        );
        const hasStockManagement = listingType?.stockType === STOCK_MULTIPLE_ITEMS;
        const stockValidator = validators.numberAtLeast(
          intl.formatMessage({ id: 'EditListingPricingAndStockForm.stockIsRequired' }),
          0
        );
        const hasInfiniteStock = STOCK_INFINITE_ITEMS.includes(listingType?.stockType);
        const currentStock = values.stock;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !formValid;
        const { updateListingError, showListingsError, setStockError } = fetchErrors || {};

        const stockErrorMessage = isOldTotalMismatchStockError(setStockError)
          ? intl.formatMessage({ id: 'EditListingPricingAndStockForm.oldStockTotalWasOutOfSync' })
          : intl.formatMessage({ id: 'EditListingPricingAndStockForm.stockUpdateFailed' });

        const packageNameRequiredMessage = intl.formatMessage({
          id: 'EditListingPackagesForm.packageNameRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingPackagesForm.maxLength' },
          {
            maxLength: PACKAGE_NAME_MAX_LENGTH,
          }
        );
        const minLengthMessage = intl.formatMessage(
          { id: 'EditListingPackagesForm.minLength' },
          {
            minLength: PACKAGE_DESCRIPTION_MIN_LENGTH,
          }
        );
        const maxLength30Message = maxLength(maxLengthMessage, PACKAGE_NAME_MAX_LENGTH);
        const minLength50Message = minLength(minLengthMessage, PACKAGE_DESCRIPTION_MIN_LENGTH);

        const handleCountryCodeChange = (form, index, newCountryCode = 'IE') => {
          const newCurrencyData = supportedCountries.find(
            country => country.code === newCountryCode
          );

          if (newCurrencyData.currency !== selectedCurrency.currency) {
            form.change(`packages[${index}].price`, null);
          }
          form.change(`packages[${index}].currency`, newCurrencyData.currency);
          form.change(`packages[${index}].countryCode`, newCurrencyData.code);
          onCurrencyChange({
            currency: newCurrencyData.currency,
            countryCode: newCountryCode,
          });
        };

        const defaultPackageValues = {
          countryCode: values.countryCode,
        };

        const currentFormValues = form.getState().values;

        return (
          <Form onSubmit={handleSubmit} className={classes}>
            {updateListingError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.updateFailed" />
              </p>
            ) : null}
            {showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingPricingForm.showListingFailed" />
              </p>
            ) : null}
            <FieldArray name="packages">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => {
                    const { countryCode } = currentFormValues.packages[index];
                    const countryCurrencyCode =
                      supportedCountries.find(country => country.code === countryCode)?.currency ||
                      'EUR';
                    const currencyConfig = appSettings.getCurrencyFormatting(countryCurrencyCode);
                    return (
                      <div key={name} className={css.packageSection}>
                        <FieldTextInput
                          className={css.input}
                          id={`${name}.packageName`}
                          name={`${name}.packageName`}
                          type="text"
                          label={intl.formatMessage({
                            id: 'EditListingPackagesForm.packageNameLabel',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'EditListingPackagesForm.packageNamePlaceholder',
                          })}
                          maxLength={PACKAGE_NAME_MAX_LENGTH}
                          validate={composeValidators(
                            required(packageNameRequiredMessage),
                            maxLength30Message
                          )}
                        />

                        <FieldTextbox
                          className={css.input}
                          id={`${name}.packageDescription`}
                          name={`${name}.packageDescription`}
                          label={intl.formatMessage({
                            id: 'EditListingPackagesForm.packageDescriptionLabel',
                          })}
                          placeholder={intl.formatMessage({
                            id: 'EditListingPackagesForm.packageDescriptionPlaceholder',
                          })}
                          minLength={PACKAGE_DESCRIPTION_MIN_LENGTH}
                          validate={composeValidators(
                            required(packageNameRequiredMessage),
                            minLength50Message
                          )}
                        />
                        <label>Country:</label>
                        <Field
                          id={`${name}.countryCode`}
                          name={`${name}.countryCode`}
                          className={css.currency}
                          label={`Country:`}
                          autoFocus={autoFocus}
                          component="select"
                          onChange={event => {
                            const newCountryCode = event.target.value;
                            handleCountryCodeChange(
                              form,
                              index,
                              newCountryCode,
                              form.getState().values
                            );
                          }}
                        >
                          <option value="">Pick something...</option>
                          {supportedCountries.map(country => (
                            <option key={country.code} value={country.code}>
                              {countryNames[country.code]}
                            </option>
                          ))}
                        </Field>
                        <FieldCurrencyInput
                          key={selectedCurrency.currency}
                          id={`${name}.price`}
                          name={`${name}.price`}
                          className={css.input}
                          label={intl.formatMessage(
                            { id: 'EditListingPricingForm.pricePerProduct' },
                            { unitType }
                          )}
                          placeholder={intl.formatMessage({
                            id: 'EditListingPricingForm.priceInputPlaceholder',
                          })}
                          currencyConfig={currencyConfig}
                          validate={priceValidators}
                        />
                        <UpdateStockToInfinityCheckboxMaybe
                          formId={formId}
                          hasInfiniteStock={hasInfiniteStock}
                          currentStock={currentStock}
                          intl={intl}
                        />

                        {hasStockManagement ? (
                          <FieldTextInput
                            className={css.input}
                            id={`${name}.stock`}
                            name={`${name}.stock`}
                            label={intl.formatMessage({
                              id: 'EditListingPricingAndStockForm.stockLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: 'EditListingPricingAndStockForm.stockPlaceholder',
                            })}
                            type="number"
                            min={0}
                            validate={stockValidator}
                          />
                        ) : (
                          <Field
                            id="stock"
                            name="stock"
                            type="hidden"
                            className={css.unitTypeHidden}
                          >
                            {fieldRenderProps => <input {...fieldRenderProps?.input} />}
                          </Field>
                        )}
                        {setStockError ? <p className={css.error}>{stockErrorMessage}</p> : null}
                        <div className={css.actionContainer}>
                          <Button
                            type="button"
                            onClick={() => {
                              setDeletePackageModal(true);
                              setPackageToDelete({
                                index: index,
                                package: currentFormValues.packages[index],
                              });
                            }}
                            className={css.removeButton}
                          >
                            {intl.formatMessage({
                              id: 'EditListingPackagesForm.removeButtonLabel',
                            })}
                          </Button>
                          {index === fields.length - 1 && (
                            <Button
                              type="button"
                              onClick={() => {
                                const newPackage = {
                                  ...defaultPackageValues,
                                  packageId: uuidv4(),
                                };
                                fields.push(newPackage);
                              }}
                              className={css.addButton}
                              disabled={addPackageDisabled}
                            >
                              {intl.formatMessage({ id: 'EditListingPackagesForm.addButtonLabel' })}
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {packageToDelete && (
                    <Modal
                      className={`${css.modalDeletePackage}`}
                      containerClassName={css.deletePackageModalContainer}
                      id="EditListingPackagesForm.removePackage"
                      isOpen={isDeletePackageModalOpen}
                      onClose={() => setDeletePackageModal(false)}
                      onManageDisableScrolling={() => {}}
                    >
                      <div className={css.modalContainer}>
                        <div className={css.modalContent}>
                          Are you sure you want to delete this package?
                        </div>
                        <div className={css.imageInfoContainer}>
                          <p>
                            <strong> {packageToDelete.package.packageName}</strong>
                          </p>
                        </div>
                        <div className={css.actionModalContainer}>
                          <Button
                            className={css.cancelDelete}
                            type="button"
                            onClick={() => setDeletePackageModal(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={css.cancelDelete}
                            type="button"
                            onClick={() => {
                              fields.remove(packageToDelete.index);
                              setDeletePackageModal(false);
                            }}
                          >
                            Delete Package
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  )}
                  {fields.length === 0 && (
                    <Button
                      type="button"
                      onClick={() => {
                        const newPackage = {
                          ...defaultPackageValues,
                          packageId: uuidv4(),
                        };
                        fields.push(newPackage);
                      }}
                      className={css.addButton}
                      disabled={addPackageDisabled}
                    >
                      {intl.formatMessage({ id: 'EditListingPackagesForm.addButtonLabel' })}
                    </Button>
                  )}
                </>
              )}
            </FieldArray>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};
EditListingPackagesFormComponent.defaultProps = {
  fetchErrors: null,
  listingMinimumPriceSubUnits: 0,
  formId: 'EditListingPricingForm',
};

EditListingPackagesFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  marketplaceCurrency: string.isRequired,
  listingMinimumPriceSubUnits: number,
  unitType: string.isRequired,
  listingType: shape({ stockType: string }).isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPackagesFormComponent);
